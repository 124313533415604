import React, { Fragment, useState, useEffect } from 'react';
import StepZilla from "react-stepzilla";
import { Row, Container, Col } from "react-bootstrap";
import IntroductionStep from './wizard-steps/introduction-step';
import DemographicsStep from './wizard-steps/demographics-step';
import IncidentStep from './wizard-steps/incident-step';
import RequestAssistanceStep from './wizard-steps/request-assistance';
import ConfirmationStep from './wizard-steps/confirmation-step';

//import styles from './wizard.less';
// import transitions from './transitions.less';
/* eslint react/prop-types: 0 */

/**
 * A basic demonstration of how to use the step wizard
 */

 
const Wizard = () => {


    const steps =
 [
   {name: 'Introduction *', component: <IntroductionStep />},
   {name: 'Demographic Questions', component: <DemographicsStep />},
   {name: 'Incident Details *', component: <IncidentStep />},
   {name: 'Assistance', component: <RequestAssistanceStep />},
   {name: 'Confirmation', component: <ConfirmationStep />}
 ]



    return (
        <Container className='container'>
            <h3>Report Incident</h3>
                <Row>
                    <Col>
                    Please use the form below to report a hate incident. Submission section marked with '*' have required fields.
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className="step-progress">
                        <StepZilla
                            steps={steps}
                            preventEnterSubmission={true}
                            nextButtonCls="btn btn-prev btn-primary btn-lg pull-right step-button"
                            backButtonCls="btn btn-prev btn-primary btn-lg pull-left step-button"
                            nextTextOnFinalActionStep={"Submit"}
                            />
                    </div>
                    </Col>
                </Row>
            
           
        </Container>
    );
};

export default Wizard;