import React, { Component } from 'react';
import { render } from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';

const racialGroups = ["Black (African, Caribbean, etc.)", "North African (Arabic speaking)", "East Asian (Chinese, Korean, Vietnamese, Indonesian etc.)", "West Asian (Turkey, Afghanistan, Iran, etc.)", "South Asian (Indian, Pakistani, Sri Lankan, Nepal, etc.)", "Indigenous Peoples (First Nations, Metis, Inuit)", "Latino (Hispanic, Latin America, etc.)", "Middle Eastern", "White (European, Caucasian, etc.)", "Prefer not to say"];
const ageGroups = ["13 and under", "14-18", "19-22", "23-45", "45-65", "65+", "Prefer not to say"];

const identityGroups = ["Female", "Male", "Non-binary", "Trans Male/Trans Man", "Trans Female/Trans Woman", "Intersex", "Let me type..", "Prefer not to say"];

const skinGroups = ["Black", "Brown", "White"];

const orientationGroups = ["Gay", "Lesbian", "Bisexual", "Heterosexual/straight", "Not listed (please specify)"]

const disabilityGroups = ["Mental disability", "Physical disability", "No", "Prefer not to say"]
const spiritualityGroups = ["Atheism/Agnosticism", "Bahá’í", "Buddhism", "Christianity", "Confucianism", "Druze", "Gnosticism", "Hinduism", "Islam", "Jainism", "Judaism", "Rastafarianism", "Shinto", "Sikhism", "Zoroastrianism", "Traditional African Religions", "African Diaspora Religions", "Indigenous American Spirituality", "Not listed (please specify)"]

const incomeGroups = ["Less than $20,000", "$20,000 to $45,000", "$45,000 to $85,000", "$85,000 to $100,000", "$100,000 to $150,000", "Greater than $150,000"]

const housingGroups = ["Owned", "Rented", "Unknown"]
const familyGroups = ["Single", "Married", "Divorced", "Widowed", "Prefer not to say"]
const DemographicsStep = () => {
    return (
        <Form>
          <p>All questions included here are optional and help us better understand our commuity.</p>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">What is your racial group, ethnicity or nationality? (check all that apply)
        </Form.Label>
        {racialGroups.map( (group, i) => {
            return <Form.Check
            type="checkbox"
            label={group}
            name="formType"
            key={i}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">What is your age group?
        </Form.Label>
        {ageGroups.map( (group, i) => {
            return <Form.Check
            type="radio"
            label={group}
            name="demoAgeGroup"
            key={i}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Which of the following most accurately describe(s) you (check all that apply):
        </Form.Label>
        {identityGroups.map( (group, i) => {
            return <Form.Check
            type="checkbox"
            label={group}
            name="demoIdentityGroup"
            key={i}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">How would you describe your skin colour?
        </Form.Label>
        {skinGroups.map( (group, i) => {
            return <Form.Check
            type="radio"
            label={group}
            name="demoSkinGroup"
            key={i}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Which of the following most accurately describes your sexual orientation (check all that apply):
        </Form.Label>
        {orientationGroups.map( (group, i) => {
            return <Form.Check
            type="checkbox"
            label={group}
            name="demoOrientationGroup"
            key={i}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Would you identify as having a mental or physical disability (check all that apply)?
        </Form.Label>
        {disabilityGroups.map( (group, i) => {
            return <Form.Check
            type="checkbox"
            label={group}
            name="demoOrientationGroup"
            key={i}
          />
        })}

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">How would you describe your spirituality?
        </Form.Label>
        <Form.Control as="select" size="lg" aria-label="Default select example">
      <option>Select...</option>
      {spiritualityGroups.map( (group, i) => {
            return <option
            value={i}
          >{group}</option>
        })}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">What is your household income?
        </Form.Label>
        <Form.Control as="select" size="lg" aria-label="Default select example">
      <option>Select...</option>
      {incomeGroups.map( (group, i) => {
            return <option
            value={i}
          >{group}</option>
        })}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">How many members do you have in your household above the age of 18?
        </Form.Label>
        <Form.Control size="lg" type="number" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">How many members do you have in your household under the age of 18?
        </Form.Label>
        <Form.Control size="lg" type="number" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">What is your housing status?
        </Form.Label>
        <Form.Control as="select" size="lg" aria-label="Default select example">
      <option>Select...</option>
      {housingGroups.map( (group, i) => {
            return <option
            value={i}
          >{group}</option>
        })}
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">What is your family status?
        </Form.Label>
        <Form.Control as="select" size="lg" aria-label="Default select example">
      <option>Select...</option>
      {familyGroups.map( (group, i) => {
            return <option
            value={i}
          >{group}</option>
        })}
        </Form.Control>
      </Form.Group>
    </Form>

    
    )
}

export default DemographicsStep;