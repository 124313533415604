import React, { Component } from 'react';
import { render } from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';

const sectorGroups = ["Online", "Residential/Home", "Recreational (Park, arena, hiking pathways, etc)", "Educational (School, College, University)", "Healthcare (clinic, hospital)", "Retail (shopping malls, stores)", "Government (city offices, emergency services)", "Workplace (where you work, or it happened within a place of employment)"]
const reasonGroups = ["Colour (Black, Brown, etc)", "Race (African, Caribbean, etc.)", "National origin/Immigration status", "Gender", "Sexuality/Sexual orientation", "Ability/Disability (physical and/or mental)", "Religious/Faith/Spirituality", "Political affiliation", "Level of household income", "Receipt of public assistance", "Housing status", "Spoken/Written Language", "Criminal Record", "Family Status", "Age", "Other (specify)"]

const IncidentStep = () => {
    return (
        <Form>
          
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Please describe the incident:
        </Form.Label>
        <Form.Control as="textarea" rows={3} />

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">You may also attach audio/video clips to describe or to share relevant details about the incident.
        </Form.Label>
        <Form.Control type="file" multiple />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Sector in which this incident occurred:
        </Form.Label>
        <Form.Control as="select" size="lg" aria-label="Default select example">
      <option>Select...</option>
      {sectorGroups.map( (group, i) => {
            return <option
            value={i}
          >{group}</option>
        })}
        </Form.Control>

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Would you like to provide more details about the location?
        </Form.Label>
        <Form.Control as="textarea" rows={3} />

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">From your perspective, what would you indicate as basis of this incident (check all that may apply):
        </Form.Label>
        {reasonGroups.map( (group, i) => {
            return <Form.Check
            type="checkbox"
            label={group}
            name="demoSkinGroup"
            key={i}
          />
        })}

      </Form.Group>

      
    </Form>
    )
}

export default IncidentStep;