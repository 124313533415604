import React, { Component } from 'react';
import { render } from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const IntroductionStep = () => {
    return (
        <Form>
      <Form.Group className="mb-3" controlId="formIncidentDate">
        <Form.Label>Date of Incident</Form.Label>
        <Form.Control
                type="date"
                name="datepic"
                placeholder="DateRange"
                
              />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formTypeSubmission">
        <Form.Label>Are you reporting on behalf of someone else or yourself?</Form.Label>
        <Form.Check
            type="radio"
            label="Myself"
            name="formType"
          />
          <Form.Check
            type="radio"
            label="Someone else"
            name="formType"
          />
      </Form.Group>
    </Form>
    )
}

export default IntroductionStep;