import React, { Component } from 'react';
import { render } from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ConfirmationStep = () => {
    return (
        <p>Thank you for your submission. We will begin reviewing this report, and action upon it based on the information provided.</p>
    )
}

export default ConfirmationStep;