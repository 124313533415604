import * as React from "react";
import PropTypes from "prop-types";
import Layout from "../layouts/index-5.jsx";
import SEO from "@components/seo";
import StepOne from "./StepOne.jsx";
import StepTwo from "./StepTwo.jsx";
import StepFour from "./StepFour.jsx";
import PageBreadcrumb from "@components/pagebreadcrumb";

import { Row, Container, Col } from "react-bootstrap";

import {
    
    BlogDetailsArea,
    
} from "../assets/css/styleReport";
import Wizard from "../components/wizard.jsx";
const steps = [
    { component: <StepOne /> },
    { component: <StepTwo /> },
    { component: <StepFour /> }
  ]

  const prevStyle = { background: '#33c3f0' }
const nextStyle = { background: '#33c3f0' }

const Report = ({ location, pageContext }) => {
    return (
        <Layout>
            <SEO title="Report Incident" pathname="/" />
            <PageBreadcrumb pageContext={pageContext} location={location} title="Report Incident" />
            <BlogDetailsArea>
                <Container>
                    <Row className="gx-5">
                        <Col lg={10}>
                            <Wizard />
                        </Col>
                        <Col lg={2}>
                            <div className="blog-details-sidebar">
                               
                            </div>
                        </Col>
                    </Row>
                </Container>
            </BlogDetailsArea>
        </Layout>
    );
};

Report.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
};


export default Report;
