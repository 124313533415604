import React, { Component } from 'react';
import { render } from 'react-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const RequestAssistanceStep = () => {
    return (
        <Form>
          
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="question-text">Would you like to be contacted for assistance, or if we want to reach out to you for further questions:
        </Form.Label>
        <Form.Check
            type="radio"
            label="No"
            name="demoAgeGroup"
           
          />
          <Form.Check
            type="radio"
            label="Yes"
            name="demoAgeGroup"
          />

      </Form.Group>

 

      
    </Form>
    )
}

export default RequestAssistanceStep;